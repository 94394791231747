// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isString, get } from "@mefisto/utils";
// Framework
import { makeStyles, classnames } from "ui";
// Components
import AvatarTooltip from "./components/AvatarTooltip";
import AnonymousAvatar from "./components/AnonymousAvatar";
import DeletedAvatar from "./components/DeletedAvatar";
import LetterAvatar from "./components/LetterAvatar";
import ImageAvatar from "./components/ImageAvatar";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ background = theme.palette.grey[100], ring }) => ({
    position: "relative",
    width: "fit-content",
    borderRadius: theme.radius.rounded,
    background,
    ...(ring
      ? {
          border: `${ring.size || 2}px solid ${
            ring.color || theme.palette.error.dark
          }`,
        }
      : {}),
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Avatar = ({
  variant = "default",
  ring,
  tooltip,
  background,
  title,
  image,
  size,
  children,
  ...rest
}) => {
  // Styles
  const classes = useStyles({ ring, background });
  // Render
  return (
    <div className={classnames(classes.root, classes.ring)} {...rest}>
      {(variant === "default" ||
        variant === "admin" ||
        variant === "disabled") && (
        <AvatarTooltip title={tooltip ? title : ""} placement="bottom">
          {isString(image) || get(image, "url") ? (
            <ImageAvatar image={image} title={title} size={size} />
          ) : (
            <LetterAvatar title={title} size={size} />
          )}
        </AvatarTooltip>
      )}
      {variant === "anonymous" && <AnonymousAvatar />}
      {variant === "deleted" && <DeletedAvatar />}
      {children}
    </div>
  );
};

Avatar.propTypes = {
  variant: PropTypes.oneOf([
    "admin",
    "regular",
    "anonymous",
    "deleted",
    "disabled",
  ]),
  tooltip: PropTypes.bool,
  background: PropTypes.string,
  ring: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.number,
  }),
  title: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      generation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    }),
  ]),
  size: PropTypes.number,
};

export default Avatar;
