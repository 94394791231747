// React
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack/core";
// Components
import { ExternalsContext } from "externals/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const External = ({ name, path }) => {
  // Framework
  const { log } = usePortal();
  // Context
  const { externals } = useContext(ExternalsContext);
  if (externals[name]) {
    log.warning(
      `${name} external was already imported. Check you externals for duplicates.`
    );
  }
  externals[name] = { name, path };
  // Render
  return <Fragment />;
};

External.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        extension: PropTypes.string.isRequired,
        devicePixelRatio: PropTypes.number,
        mediaType: PropTypes.string,
        codecs: PropTypes.string,
      })
    ),
  ]),
};

export default External;
